import { AnimatePresence } from "framer-motion";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import RedesignedLanding from "components/landing-redesign/main";
import NewsletterModal from "components/landing-redesign/newsletterModal";
import SharedHeader from "global/head/header";

export default function Home() {
  const userInfo = useSelector((state: any) => state.auth.userInfo);

  // @ts-ignore
  const token = useSelector((state) => state.auth.token);

  const [showNewsletterModal, setShowNewsletterModal] = useState(false);
  const [hasSeenNewsletter, setHasSeenNewsletter] = useState(true);

  useEffect(() => {
    // get newsletterTime time and see if it's been 6hrs
    const newsletterTime = localStorage.getItem("newsletterTime");
    if (newsletterTime) {
      const time = new Date().getTime();
      const diff = time - Number(newsletterTime);
      // if it's been 6hrs
      if (diff > 21600000) {
        setHasSeenNewsletter(false);
      } else {
        setHasSeenNewsletter(true);
      }
    } else {
      setHasSeenNewsletter(false);
    }
  }, []);

  useEffect(() => {
    if (
      !hasSeenNewsletter &&
      localStorage.getItem("hasSeenNewsletter") === null
    ) {
      setTimeout(() => {
        // record time
        localStorage.setItem("newsletterTime", Date.now().toString());
        setShowNewsletterModal(true);
      }, 15000);
    }
  }, [hasSeenNewsletter]);

  const router = useRouter();

  useEffect(() => {
    if (token && userInfo?.role === "business" && userInfo?.handle) {
      router.push("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, token]);

  return (
    <>
      <SharedHeader />
      <Head>
        <link
          rel="preload"
          href="https://res.cloudinary.com/knackapp/image/upload/c_scale,w_1440/v1688993408/static/2a571c01e812b9d08b5110fa13b32681.png"
          as="image"
        />
      </Head>
      <main className="">
        <RedesignedLanding />
        <AnimatePresence>
          {showNewsletterModal && (
            <NewsletterModal closeModal={() => setShowNewsletterModal(false)} />
          )}
        </AnimatePresence>
      </main>
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {},
  };
}
