import Image from "next/image";
import React from "react";

import { Karma } from "next/font/google";

import { motion } from "framer-motion";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

export default function KnackAppAddons() {
  const data = [
    {
      id: 0,
      title: "Slick desktop app",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689939867/static/business/features/cf192242549d99e0a7a6918fb03b851c.png",
    },
    {
      id: 1,
      title: "Extraordinary engagement",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689249945/static/business/features/a04d348757af90005d26a3448e030017.png",
    },
    {
      id: 2,
      title: "Templates for every purpose",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689249945/static/business/features/a9017c20217babd809ceae5bf18f8545.png",
    },
    {
      id: 3,
      title: "Customize reports in a click",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689249945/static/business/features/39aedce025fd351777e0df370b6c0215.png",
    },
    {
      id: 4,
      title: "Branded invite pages",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689249944/static/business/features/83b6f391dc7d68f26541161e04bd4f28.png",
    },
    {
      id: 5,
      title: "Automated smart nudges",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689249945/static/business/features/90f2571abdee1759f8e78279018b46e5.png",
    },
    {
      id: 6,
      title: "QR-code and URL invites",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689249944/static/business/features/18115a73986734c7c91da1a3ecba7453.png",
    },
    {
      id: 7,
      title: "Automated bulk invites",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689249943/static/business/features/68b2b06904dd8f17806e488cfa5c85b8.png",
    },
    {
      id: 8,
      title: "Integrations to ATS, LMS, CRM",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689249943/static/business/features/e7d160f0804ecf7d483d8d2b255eaf2c.png",
    },
    {
      id: 9,
      title: "Advanced data security",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689249943/static/business/features/cde1b3974536f1d629600c392a8890ae.png",
    },
    {
      id: 10,
      title: "Excel and PDF exports",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689249943/static/business/features/587e16a24a5429e4a289b70823c04fe6.png",
    },
    {
      id: 11,
      title: "Available in 20 languages",
      image:
        "https://res.cloudinary.com/knackapp/image/upload/v1689249944/static/business/features/abae6db6e583372748af08d101d04193.png",
    },
  ];
  return (
    <section
      id="latest-features-section"
      className="flex w-full flex-col gap-8 bg-white px-4 py-[6rem] text-center md:px-8"
    >
      <h2
        className={`text-[28px] font-bold leading-[100%] tracking-normal text-[#191919] lg:text-[38px] ${font.className}`}
      >
        Popular Features You&apos;ll Love
      </h2>
      <div className="mx-auto flex flex-wrap justify-center gap-4 gap-y-6 2xl:max-w-7xl">
        {data.map((item, i) => (
          <OneFeature key={item.id} {...item} delay={i * 0.1} />
        ))}
      </div>
    </section>
  );
}

function OneFeature({
  image,
  title,
  delay,
}: {
  image: string;
  title: string;
  delay: number;
}) {
  return (
    <motion.div
      initial={{ x: 10, y: 10 }}
      whileInView={{ y: 0, x: 0 }}
      transition={{ duration: 0.2, ease: "easeIn", delay }}
      className="flex flex-col gap-1 md:max-w-[250px] 2xl:max-w-[300px]"
      viewport={{ amount: 0.1, once: true }}
    >
      <Image src={image} height={260} width={300} alt={`KnackApp ${title}`} />
      <h3 className="tetx-lg font-medium text-[#191919]">{title}</h3>
    </motion.div>
  );
}
