import { Karma } from "next/font/google";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});
const ResearchData = [
  {
    title: "Shell case study on spotting superstar innovators",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/v1689163371/static/research/v2/419fee3fcf68df7a8b31d9d1ca0dba8e.png",
    link: "https://s3.amazonaws.com/assets.knack.it/research/Royal+Dutch+Shell+Case+Study+-+The+Atlantic.pdf",
  },
  {
    title: "NYU research on identifying the high potential surgeons",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto,q_100/v1689318016/static/research/v2/7e3331ac5bde08fc236f84fe6752f471.png",
    link: "https://s3.amazonaws.com/assets.knack.it/research/Research+on+Medical+Talent+in+NYC++.pdf",
  },
  {
    title: "US Government report on identifying youth potential",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto,q_100/v1689318032/static/research/v2/0835030adf4bd4f21dfc68803bd07936.png",
    link: "https://s3.amazonaws.com/assets.knack.it/research/US+Government+Recommendations+on+Measuring+Soft+and+Life+Skills.pdf",
  },
  {
    title: "Rockefeller Foundation research on hiring high potential youth",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto,q_100/v1689318017/static/research/v2/bba7aa2dedf000cdc831e0ba0c748260.png",
    link: "https://s3.amazonaws.com/assets.knack.it/research/American+Impact+Hiring.pdf",
  },
  {
    title: "Harvard research on identifying hidden diamonds in the rough",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto,q_100/v1689318017/static/research/v2/c6f3f3a3cea2f173f97777a540455996.png",
    link: "https://s3.amazonaws.com/assets.knack.it/research/Harvard+Kennedy+Research.pdf",
  },
];

export default function KnackResearchReport() {
  return (
    <section className="mx-auto my-10 flex flex-col gap-10 bg-white">
      <div className="flex flex-col gap-0">
        <h2
          className={`${font.className} text-center text-[30px] font-bold text-[#191919] md:text-[44px]`}
        >
          Popular Case Studies & Research Reports
        </h2>
        <div className="mb-10 mt-4 flex flex-col items-center justify-center gap-0 text-[16px] md:flex-row md:gap-2 md:text-[20px]">
          <Link
            href="/publications"
            className={`text-center font-medium text-[#018906] underline `}
          >
            Explore our Knowledge Center
          </Link>

          <span>and</span>
          <Link
            href="/books"
            className={`text-center text-[16px]  font-medium text-[#018906] underline md:text-[20px]`}
          >
            browse the books
          </Link>
        </div>
      </div>

      {ResearchData.map((research) => (
        <KnackResearch
          key={research.title}
          title={research.title}
          image={research.image}
          link={research.link}
        />
      ))}
    </section>
  );
}

function KnackResearch({
  title,
  link,
  image,
}: {
  title: string;
  link: string;
  image: string;
}) {
  return (
    <section className="mx-auto mb-8 flex w-full flex-col items-center justify-between bg-white md:max-w-5xl">
      <div className="flex w-full items-center justify-center">
        <div className="flex w-full flex-col items-center justify-between gap-10 px-8 md:flex md:flex-row md:gap-40">
          <div className="mb-1 w-[100%] md:mb-4  md:w-[40%]">
            <h3 className="flex w-full flex-col text-center text-[30px] font-[500] leading-10 text-[#191919] md:text-start">
              {title}
            </h3>
            <div className="mx-auto mt-5 w-max md:mx-0">
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="download w-max rounded border-2 border-[#018906] px-12 py-2  text-[#018906] transition-all duration-300 ease-in"
              >
                Download
              </a>
            </div>
          </div>

          <Image
            src={image}
            alt={title}
            width={400}
            className="max-h-[300px] md:max-w-[370px] md:object-contain"
            height={230}
          />
        </div>
      </div>
    </section>
  );
}
