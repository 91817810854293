/* eslint-disable no-return-assign */
import { motion } from "framer-motion";
import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";

import { testimonials } from "mock/testimonials";

export default function Testimonials({ close }: { close: () => void }) {
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerWidthMobile, setContainerWidthMobile] = useState<number>(0);

  useEffect(() => {
    const snapEl = document.getElementById("testimonial-snap");
    const snapElMobile = document.getElementById("testimonial-snap-mobile");
    if (snapEl) {
      setContainerWidth(snapEl.offsetWidth);
    }
    if (snapElMobile) {
      setContainerWidthMobile(snapElMobile.offsetWidth);
    }
  }, []);

  const scrollLeftOrRight = (dir: string, id = "testimonial-snap") => {
    // on scrollable snap element od id "hero-snap", scroll right or left full width
    const snapEl = document.getElementById(id);
    if (snapEl && id === "testimonial-snap") {
      console.log(activeSnap);
      snapEl.scrollLeft +=
        dir === "next" ? containerWidth : containerWidth * -1;
    }
    if (snapEl && id === "testimonial-snap-mobile") {
      snapEl.scrollLeft +=
        dir === "next" ? containerWidthMobile : containerWidthMobile * -1;
    }
  };

  const [activeSnap, setActiveSnap] = useState(0);

  const imageRef = useRef<any[]>([]);

  useEffect(() => {
    imageRef.current = imageRef.current.slice(0, testimonials?.length);
  }, []);

  const onIntersectionObserved = (
    entries: { isIntersecting: any; intersectionRatio: number; target: any }[]
  ) => {
    entries.forEach(
      (entry: {
        isIntersecting: any;
        intersectionRatio: number;
        target: any;
      }) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.8) {
          const intersectingIndex = imageRef.current.indexOf(entry.target);
          setActiveSnap(intersectingIndex);
        }
      }
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersectionObserved, {
      threshold: 0.8,
    });
    if (imageRef.current.length > 0) {
      imageRef.current.forEach((item) => {
        observer.observe(item);
      });
    }
  }, [imageRef]);

  return (
    <section
      onClick={(e) => e.target === e.currentTarget && close()}
      className="fixed inset-0 z-[130] flex h-screen w-screen flex-col items-center justify-center bg-black/50 backdrop-blur-sm"
    >
      <motion.section
        initial={{ scale: 0.8, opacity: 0.7 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.5, opacity: 0, transition: { duration: 0.5 } }}
        transition={{ duration: 0.3, ease: [0.25, 0.1, 0.25, 1.0] }}
        className="relative flex h-[450px] w-full items-center justify-center md:h-[80vh] md:w-[90%]"
      >
        <div
          onClick={() => close()}
          className="absolute -top-12 right-2 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-white/90 md:-right-12 md:-top-1"
        >
          <IoClose className="" color="#2B2A2A" size={25} />
        </div>
        <Image
          src="https://res.cloudinary.com/knackapp/image/upload/f_auto/v1685440165/fba1e18b98132ce093a5ae9aebebb9ee.png"
          fill
          alt="KnackApp Testimonials"
          className="h-full w-full object-cover object-center"
        />
        <div className="relative z-50 flex w-full flex-col items-center gap-8 text-white md:w-max md:flex-row">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="absolute left-0 z-50 hidden h-16 w-16 transform cursor-pointer md:relative md:block"
            onClick={() => scrollLeftOrRight("prev")}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>

          <div
            id="testimonial-snap"
            className="no-scrollbar relative z-40 flex h-[450px] w-screen overflow-scroll md:h-auto md:w-[900px] md:snap-x md:snap-mandatory md:rounded-[5px]"
          >
            <Image
              src="https://res.cloudinary.com/knackapp/image/upload/f_auto/v1685440165/fba1e18b98132ce093a5ae9aebebb9ee.png"
              fill
              alt="KnackApp Testimonials"
              className="relative z-30 block h-full w-full object-cover object-center md:hidden"
            />
            {testimonials.map((testimonial, j) => (
              <div
                key={testimonial.id}
                ref={(el) => (imageRef.current[j] = el)}
                className="relative z-50 flex h-[450px] w-[100vw] min-w-[100vw] flex-col gap-4 bg-transparent p-8 pb-0 pl-16 md:h-auto md:w-[900px] md:min-w-[900px] md:snap-start md:snap-always md:rounded-[5px] md:bg-[#1B1A1A] md:p-16"
              >
                <div className="go-to-play-modal-overlay absolute inset-0 z-40 block h-full w-full md:hidden" />
                <div className="relative z-50 flex w-full items-end justify-end">
                  <span className="text-[18px] font-semibold text-[#00DF79]">
                    {testimonial.category}
                  </span>
                </div>
                <div className="relative z-50 flex flex-col text-white">
                  <span className="-mb-16 -ml-10 mr-4 text-8xl">“</span>
                  <p className="text-lg font-medium leading-[150%] md:text-xl">
                    {testimonial.text}
                  </p>
                </div>
                <p className="relative z-50 mt-4 font-medium text-white">
                  {testimonial.name.split("\n").map((txt, i) => (
                    <span key={i}>
                      {txt}
                      <br />
                    </span>
                  ))}
                </p>
              </div>
            ))}
          </div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="absolute right-0 z-50 hidden h-16 w-16 transform cursor-pointer md:relative md:block"
            onClick={() => scrollLeftOrRight("next")}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </motion.section>
    </section>
  );
}
