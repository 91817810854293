import { AnimatePresence, motion } from "framer-motion";
import { Karma } from "next/font/google";
import Link from "next/link";
import React, { useEffect, useState } from "react";

import FunGames from "./funGames";
import GoodCompany from "./goodCompany";
import LandingHero from "./hero";
import ImpactSection from "./impactSection";
import KnackAppAddons from "./knackAppAddons";
import KnackFeatures from "./knackFeatures";
import KnackResearchReport from "./knackResearch";
import { KnackAppLogo } from "./navbar";
import ContactUs from "./needHelp";
import { SubscribeSection } from "./newsletterModal";
import Pricing from "./pricing";
import Footer from "./v2.footer";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

export default function RedesignedLanding() {
  const [showBottomNav, setShowBottomNav] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowBottomNav(true);
      } else {
        setShowBottomNav(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className="relative overflow-hidden bg-white">
      <LandingHero />
      <Pricing />
      <GoodCompany />
      <SubscribeSection />
      <FunGames />
      <KnackFeatures />
      <ImpactSection />
      <KnackAppAddons />
      <KnackResearchReport />
      <SubscribeSection />
      <ContactUs />
      <Footer />
      <AnimatePresence>
        {showBottomNav && (
          <motion.aside
            initial={{ opacity: 0, y: "100%" }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            exit={{ opacity: 0, y: "100%" }}
            className="shadow-sticky fixed inset-x-0 bottom-0 z-50 hidden h-[80px] w-full justify-between bg-white px-5 md:flex"
          >
            <div className="flex items-center gap-4">
              <KnackAppLogo showSolutionsModal={false} />
              <p
                className={`mt-1 text-2xl font-bold !leading-[333%] text-[#191919] ${font.className}`}
              >
                Spot superstars in minutes!
              </p>
            </div>
            <div className="flex h-full flex-row-reverse items-center gap-4 text-[#585858]">
              <Link
                href="/login/business"
                className={`rounded-md border-[1px]  border-[#018906] px-8 py-2 text-[14px] font-semibold text-[#018906] transition-all duration-150 ease-in hover:bg-[#018906]/20`}
              >
                Login
              </Link>
              <Link
                href="/signup/business"
                className={`rounded-md border-[1px] border-[#018906] bg-[#018906] px-8 py-2 text-[14px] font-semibold text-white transition-all duration-150 ease-in hover:bg-[#016204]`}
              >
                Get free account
              </Link>
            </div>
          </motion.aside>
        )}
      </AnimatePresence>
    </section>
  );
}
